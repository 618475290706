<template>
  <div class="sponsoren">
    <div class="sponsoren__text">
      <h5 class="sponsoren__headline">
        Der TSV Goldbach dankt seinen Unterstützern
      </h5>

      <a
        class="sponsoren__link"
        href="https://www.metzgereibetz.de/"
        target="_blank"
        ><img
          class="sponsoren__image"
          src="@/assets/imgs/sponsoren/betz.png"
          alt="Betz"
      /></a>
      <a
        class="sponsoren__link"
        href="https://fahrschule-goeller.de/"
        target="_blank"
        ><img
          class="sponsoren__image"
          src="@/assets/imgs/sponsoren/goeller.png"
          alt="Goeller"
      /></a>
      <a
        class="sponsoren__link"
        href="https://www.alexander-hernadi.de/"
        target="_blank"
        ><img
          class="sponsoren__image"
          src="@/assets/imgs/sponsoren/hernadi.png"
          alt="Hernadi"
      /></a>
      <a class="sponsoren__link" href="https://auto-linke.de/" target="_blank"
        ><img
          class="sponsoren__image"
          src="@/assets/imgs/sponsoren/linke.png"
          alt="Linke"
      /></a>
      <a
        class="sponsoren__link"
        href="https://outlet-hohenlohe.de/"
        target="_blank"
        ><img
          class="sponsoren__image"
          src="@/assets/imgs/sponsoren/outlethohenlohe.png"
          alt="Outlet Hohenlohe"
      /></a>
      <a class="sponsoren__link" href="https://www.r-weiss.de/" target="_blank"
        ><img
          class="sponsoren__image"
          src="@/assets/imgs/sponsoren/rweiss.png"
          alt="R Weiss"
      /></a>
      <a
        class="sponsoren__link"
        href="https://www.straub-sicherheit.de/"
        target="_blank"
        ><img
          class="sponsoren__image"
          src="@/assets/imgs/sponsoren/straub.png"
          alt="Straub"
      /></a>
      <a
        class="sponsoren__link"
        href="https://www.zieglers-hofladen.de/"
        target="_blank"
        ><img
          class="sponsoren__image"
          src="@/assets/imgs/sponsoren/zieglers.png"
          alt="Zieglers"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SponsorenComponente",
  props: {},
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/_colors.scss";
.sponsoren {
  color: $white;
  padding: 32px 24px 64px 24px;
  margin-top: 32px;
  background: rgba(0, 0, 0, 0.25);
  border-top: 1px solid rgba($white, 0.1);
  &__headline {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 4px;
    opacity: 0.75;
    margin-bottom: 32px;
  }

  &__image {
    filter: grayscale(1) contrast(0.3);
    height: 100px;
    width: auto;
    border-radius: 8px;
    &:hover {
      filter: contrast(1);
      background: rgba($white, 1);
    }
  }
}
</style>
