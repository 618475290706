import HomeView from "@/views/HomeView.vue";
import SponsorenView from "@/views/SponsorenView.vue";
import AbteilungFussball from "@/views/AbteilungFussball.vue";
import AbteilungSchuetzen from "@/views/AbteilungSchuetzen.vue";
import AbteilungTischtennis from "@/views/AbteilungTischtennis.vue";
import AbteilungTurnen from "@/views/AbteilungTurnen.vue";

import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sponsoren",
    name: "sponsoren",
    component: SponsorenView,
  },
  {
    path: "/fussball",
    name: "fussball",
    component: AbteilungFussball,
  },
  {
    path: "/schuetzen",
    name: "schuetzen",
    component: AbteilungSchuetzen,
  },
  {
    path: "/tischtennis",
    name: "Tischtennis",
    component: AbteilungTischtennis,
  },
  {
    path: "/turnen",
    name: "Turnen",
    component: AbteilungTurnen,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/HomeView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  //history: createMemoryHistory(process.env.BASE_URL),
  //historyApiFallback: true,
  routes,
  //mode: "hash",
});

export default router;

/*
um das /#/... in der url los zu werden muss wahrscheinlich folgendes in die .htaccess im root

<IfModule mod_rewrite.c>
  RewriteEngine On
  RewriteBase /
  RewriteRule ^index\.html$ - [L]
  RewriteCond %{REQUEST_FILENAME} !-f
  RewriteCond %{REQUEST_FILENAME} !-d
  RewriteRule . /index.html [L]
</IfModule>


*/
