<template>
  <HeroComponente
    icon=""
    headline="Willkommen"
    subline="beim TSV Goldbach"
    img="home-testbild.jpg"
    video=""
  />

  <div class="content__container">
    <div class="content__wrapper content__wrapper--first">
      <div class="content__left">
        <h1 class="content__left__maintitle">Mir schaffet d'rah</h1>
        <p>Diese Seite befindet sich im Aufbau.</p>

        <h2>Kontakt</h2>
        <p>
          Akazienweg 7<br />
          74564 Crailsheim
        </p>
        <p>07951 / 42648</p>
        <p>info@tsv-goldbach.de</p>
      </div>
      <!-- 
        <div class="content__right">
       
        <LigaTabelle headline="Kreisliga A2" />
       
      </div>
       -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroComponente from "@/components/HeroComponente.vue";
//import LigaTabelle from "@/components/LigaTabelle.vue";

export default {
  name: "HomeView",
  components: {
    HeroComponente,
    //LigaTabelle,
  },
};
</script>
