<template>
  <div class="hero">
    <img
      v-if="img"
      class="hero__img"
      alt="TSV Goldbach"
      :src="require(`../assets/imgs/hero/${img}`)"
    />
    <video v-if="video" class="hero__video" muted autoplay loop>
      <source
        :src="require(`../assets/videos/hero/${video}`)"
        type="video/webm"
      />
    </video>
    <div class="hero__overlay"></div>

    <div v-if="headline" class="hero__container">
      <img
        v-if="icon"
        class="hero__msg__icon"
        :src="require(`../assets/imgs/${icon}`)"
      />
      <div class="hero__stripe hero__stripe--one"></div>
      <div class="hero__stripe hero__stripe--two"></div>
      <div class="hero__stripe hero__stripe--three"></div>
      <div class="hero__stripe hero__stripe--four"></div>
      <div class="hero__stripe hero__stripe--five"></div>
      <div class="hero__stripe hero__stripe--six"></div>
      <div
        class="hero__msg hero__msg__background"
        :style="color ? 'box-shadow: 0.2em 0.2em 0' + color : ''"
      >
        {{ headline }}
      </div>
      <div class="hero__msg">
        {{ headline }}
        <div class="hero__msg__subline">{{ subline }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroComponente",
  props: {
    headline: String,
    subline: String,
    icon: String,
    img: String,
    video: String,
    color: String,
  },
  methods: {},
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "src/assets/scss/_colors.scss";

.hero {
  position: absolute;
  z-index: 0;
  background: rgba($black, 1);
  background: linear-gradient(
    356deg,
    rgba($black, 1) 67%,
    rgba($black--light, 1) 100%
  );
  color: rgba($white, 1);
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  border-bottom: 4px solid rgba($black, 1);
  box-shadow: 0 0 1px rgba($white, 1);

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  &__img {
    position: absolute;
    /*left: 0;
    right: 0;
    top: 0;
    bottom: 0;*/
    margin: auto;
    //max-width: 100%;
    width: 100%;
    height: auto;
    opacity: 0.5;
    //filter: sepia(0.75);
    //filter: saturate(0.75);
  }

  &__video {
    width: 100%;
    height: auto;
    //opacity: 0.25;
    //filter: saturate(0.5);
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter: brightness(0.5);
    //opacity: 0.1;
    background: transparent url("../assets/imgs/hero/video-overlay.png");
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      height: 25%;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba($black, 1) 0%,
        rgba($black, 0) 100%
      );
    }
    &:after {
      top: auto;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba($black, 1) 0%,
        rgba($black, 0) 100%
      );
    }
  }

  &__msg {
    position: absolute;
    //top: 40px;
    left: -270px;
    width: 800px;
    //width: 600px;
    //padding-right: 8vw;
    //padding: 0 0 15px 300px;
    font-family: "condensed", "Roboto", sans-serif;
    font-size: 55px;
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
    //text-align: right;
    color: rgba($black, 1);
    transform: rotate(310deg);
    //transform-origin: 50% 100%;
    padding: 24px 0;
    @media (min-width: 800px) {
      top: 70px;
    }
    &__background {
      padding: 10px;
      color: rgba($white, 1);
      text-indent: -4%;
      background: rgba($white, 1);
      background: linear-gradient(
        185deg,
        rgba($white, 1) 37%,
        rgba($white--dark, 1) 100%
      );
      //width: 800px;
      box-shadow: 0.2em 0.2em 0 rgba($brown--light, 1);
      border: 4px solid rgba($black, 1);
    }
    &__icon {
      height: 125%;
      width: auto;
      opacity: 0.05;
    }

    &__subline {
      opacity: 0.5;
      line-height: 16px;
      font-size: 22px;
      position: relative;
      top: -20px;
      right: 26px;
      //text-align: right;
    }
  }
  &__stripe {
    position: absolute;
    bottom: 0px;
    background: rgba($white, 0.5);
    width: 800px;
    padding: 4%;
    transform-origin: 50% 50%;
    transform: rotate(310deg) skew(-15deg, 0deg);
    animation: slidein 1s 0.4s 1 forwards;
    border-bottom: 25px solid rgba($white, 0.25);
    &--one {
      left: -560px;
      //width: 730px;
      opacity: 0.15;
    }
    &--two {
      left: -80px;
      //width: 840px;
      //padding: 2.5% 4%;
      opacity: 0.25;
      transform: rotate(310deg) skew(-15deg, 0deg) scale(0.75);
    }
    &--three {
      left: -6%;
      //width: 700px;
      //padding: 7% 10%;
      //bottom: 0px;
      opacity: 0.1;
      transform: rotate(310deg) skew(-15deg, 0deg) scale(1.25);
    }
    &--four {
      left: 40%;
      //width: 800px;
      //padding: 3.5% 4%;
      opacity: 0.35;
    }
    &--five {
      left: 57%;
      //width: 800px;
      //padding: 5% 4%;
      opacity: 0.1;
      transform: rotate(310deg) skew(-15deg, 0deg) scale(1.15);
    }
    &--six {
      left: 80%;
      //width: 200px;
      //padding: 5% 4%;
      opacity: 0.15;
      transform: rotate(310deg) skew(-15deg, 0deg) scale(1.5);
    }
  }
}
@keyframes shad-anim {
  0% {
    background-position: 0 0;
  }
  0% {
    background-position: 100% -100%;
  }
}
@keyframes slidein {
  0% {
    translate: -50% 275%;
  }
  100% {
    translate: 0% 0%;
  }
}
</style>
